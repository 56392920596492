import React, { useEffect, useState } from "react";
import { HeaderBig, HeaderMini, HomeContent } from "./header";
import "../scss/index.scss";
import { CharityHero } from "./charityhero";
import { HWCH } from "./hwch";
import { ButtonUp, CarouselPartner } from "./modail";
import { AboutAs } from "./aboutas";
import { Footer } from "./footer";
import "../scss/media.scss";
import BGTop from "../img/bgtop.png";
import { Loade } from "./load";
import { useTranslation } from "react-i18next";
import { CarouselBox } from "./carouselBox";
import { Bgcarousel } from "./bgcarousel";
import { DonateModail } from "./modail";

const IP_CHECKER_URL = "https://www.cloudflare.com/cdn-cgi/trace";

function App() {
  const [userLocation, setUserLocation] = useState("ua");

  const { i18n } = useTranslation();

  const convertResponseStringToObject = (dataString) => {
    const userLocationData = dataString
      .trim()
      .split("\n")
      .reduce((obj, pair) => {
        pair = pair.split("=");
        return (obj[pair[0]] = pair[1]), obj;
      }, {});

    const location = userLocationData.loc.toLowerCase();
    setUserLocation(location);
  };

  const fetchError = (error) => {
    console.error("Error fetching data:", error);
    setUserLocation(i18n.resolvedLanguage);
  };

  useEffect(() => {
    const fetchIpData = async () => {
      await fetch(IP_CHECKER_URL)
        .then((resp) => {
          if (resp.ok) {
            return resp.text();
          }
          throw resp;
        })
        .then((data) => convertResponseStringToObject(data))
        .catch((error) => fetchError(error));
    };
    fetchIpData();
  }, []);
  return (
    <>
      <Loade></Loade>
      <div className="top-backgraund">
        <div>
          <img src={BGTop} alt="" />
        </div>
      </div>
      <HeaderMini></HeaderMini>
      <HeaderBig></HeaderBig>
      <HomeContent location={userLocation} />
      <CarouselPartner></CarouselPartner>
      <HWCH></HWCH>
      <CharityHero></CharityHero>
      <div className="midle-backgraund">
        <div>
          <img src={BGTop} alt="" />
        </div>
      </div>
      <Bgcarousel></Bgcarousel>
      <CarouselBox></CarouselBox>
      <DonateModail></DonateModail>
      <AboutAs></AboutAs>
      <Footer></Footer>
      <ButtonUp></ButtonUp>
    </>
  );
}

export default App;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./locales/en/translations.json";
import translationUK from "./locales/uk/translations.json";
import translationES from "./locales/es/translations.json";
import translationPL from "./locales/pl/translations.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: (code) => (code === "uk" || code === "ru") ? "uk" : "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: translationEN
      },
      uk: {
        translation: translationUK
      },
      es: {
        translation: translationES
      },
      pl: {
        translation: translationPL
      }
    }
  });

  export default i18n;
import React from "react";

export class ButtonCost extends React.Component {
  render() {
    return (
      <button
        id={this.props.id}
        onClick={this.location}
        className="cost-button"
      >
        {this.props.id} ₴
      </button>
    );
  }
}

export class BtnCostInternational extends React.Component {
  render() {
    return (
      <a href={this.props.href}>
        <button
          id={this.props.id}
          onClick={this.location}
          className="cost-button cost-btn_form"
        >
          {this.props.id} EUR
        </button>
      </a>
    );
  }
}

import React from "react";
import { useTranslation } from "react-i18next";
import "../scss/charityHero.scss";
import Hitw from "../img/hwch/hitw.png";
import Haip from "../img/hwch/yhalp.png";
import Vdh from "../img/hwch/vdh.png";
import Bukr from "../img/hwch/bukr.png";
import Group1234 from "../img/hwch/Group1234.png";

export function CharityHero() {
  const { t } = useTranslation();
  const modail = t("how-its-work.modail1", { returnObjects: true });

  return (
    <div className="how-its-worck">
      <div className="how-its-worck_title">
        <span className="title">{t("how-its-work.title1")}</span>
      </div>

      <div className="motherfucker">
        {modail.map((el) => {
          return (
            <div className="hwch" key={el.id}>
              <div className="mot_1">
                <div className="hwch">
                  {el.id == 0 || el.id == 2 ? (
                    <>
                      {el.id == 0 ? (
                        <div className="pick kp1">
                          <img className="img_1 234img" src={Hitw} alt="" />
                        </div>
                      ) : (
                        <div className="pick kp2">
                          <img className="img_2 234img" src={Haip} alt="" />
                        </div>
                      )}
                      <span className="title-och">{el.title}</span>
                      <p className="info-och">{el.info}</p>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="mot_3">
        <img className="img_1234" src={Group1234} alt="" />
      </div>

      <div className="motherfucker">
        {modail.map((el) => {
          return (
            <div className="hwch" key={el.id}>
              <div className="mot_2">
                <div className="hwch">
                  {el.id == 1 || el.id == 3 ? (
                    <>
                      {el.id == 1 ? (
                        <div className="pick kp3">
                          <img className="img_3 234img" src={Bukr} alt="" />
                        </div>
                      ) : (
                        <div className="pick kp4">
                          <img className="img_4 234img" src={Vdh} alt="" />
                        </div>
                      )}
                      <span className="title-och">{el.title}</span>
                      <p className="info-och">{el.info}</p>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

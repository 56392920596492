import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";

import "../scss/modail.scss";

import LApplePay from "../img/modail/logos_apple-pay.svg";
import LGooglePay from "../img/modail/logos_google-pay.svg";
import GooglePlay from "../img/modail/downGoogle.jpg";
import ApplePlay from "../img/modail/downApple.jpg";
import Arrow from "../img/modail/Arrow.svg";
import ArrowUp from "../img/up-arrow.svg";

//=======logo====================
import logoPartners from "../img/partner/partnerData";

export function CarouselPartner() {
  const { t } = useTranslation();
  return (
    <div className="our-support-wrapper">
      <h3 className="our-support" id="partners">
        {t("supported-partners")}{" "}
      </h3>
      <div className="carousel-partner">
        <Marquee speed={50} gradient={false}>
          {logoPartners.map(({ id, url, name }) => {
            return (
              <img
                className="carousel-partner_image"
                key={id}
                src={url}
                alt={name}
                srcSet=""
              />
            );
          })}
        </Marquee>
      </div>
      <div id="partners"></div>
    </div>
  );
}

export function DownloadModail() {
  const { t } = useTranslation();

  return (
    <div className="modail-download">
      <div className="modail-backgound"></div>
      <span>{t("modail-download")}</span>
      <div className="modail-download_button-download">
        <a href="https://play.google.com/store/apps/details?id=com.magnetto.CharityHero&hl=ru&gl=US">
          <img className="modail-download_image" src={GooglePlay} alt="" />
        </a>
        <a href="https://apps.apple.com/us/app/charity-hero/id1612487637">
          <img className="modail-download_image" src={ApplePlay} alt="" />
        </a>
      </div>
    </div>
  );
}

export function DonateModail() {
  const { t } = useTranslation();
  return (
    <div className="donation-modail">
      <div className="modail-backgound"></div>     
      <span>{t("want-to-support")}</span>
      <a href="#donate-form">
      <div className="donation-modail_store">
        <img src={Arrow} alt="" className="arrow" />
        <img src={LGooglePay} alt="GooglePay" />
        <img src={LApplePay} alt="ApplePay" />
      </div>
      </a>
    </div>
  );
}

export function ButtonMakeADeposit(props) {
  return (
    <a
      className="button-donation button-donation__header"
      href={
        props.location === "ua"
          ? "https://ua.charityhero.live/"
          : "https://worldwide.charityhero.live/"
      }
    >
      {props.children}
    </a>
  );
}

export function DonloadAppModail() {
  return (
    <div>
      <span></span>
      <div>
        <img src="" alt="" />
        <img src="" alt="" />
      </div>
    </div>
  );
}

export function ButtonUp() {
  const [act, stateAct] = useState(false);
  const [top, stateTop] = useState(0);
  const [scroll, stateScroll] = useState(0);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      stateTop(window.pageYOffset);
      stateScroll(window.pageYOffset - top);

      if (top >= 1000) {
        if (scroll >= 0) {
          stateAct(false);
        } else if (scroll <= 0) {
          stateAct(true);
        }
      } else if (top <= 1000) {
        stateAct(false);
      }
    });
  });

  return (
    <button
      className={act ? "button-up" : "button-up button-up__dis"}
      id="header"
    >
      <img src={ArrowUp} alt="" />
    </button>
  );
}

import React, { useEffect, useState } from "react";
import "../scss/cost.scss";
import { BtnCostInternational } from "./button";
import logoApple from "../img/modail/logos_apple-pay.svg";
import logoGoogle from "../img/modail/logos_google-pay.svg";
import logoVisa from "../img/modail/logos_visa.svg";
import logoMaster from "../img/modail/logos_master.svg";
import { useTranslation } from "react-i18next";

export function CostInternational() {
  const [act, stateAct] = useState(false);
  const [id, stateId] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const location = document.location.search;
    const idPoster = location.split("=")[1];

    if (idPoster === "1") {
      stateId(false);
    } else if (idPoster === "2") {
      stateId(true);
    } else {
      stateId(false);
    }
  }, []);

  return (
    <div
      className={act ? "cost-international cost__act" : "cost-international"}
    >
      <div className="cost-international_wrapper">
        <span className="cost_title">{t("home-content_sub-title")}</span>
      </div>

      <div
        className={
          act
            ? "cost-international_button cost_button__act"
            : "cost-international_button"
        }
      >
        <div className="box-wrapper_form ">
          <BtnCostInternational
            id="5"
            href={id ? "" : "https://donate.stripe.com/bIY8yG8LO8svgggbK3"}
          ></BtnCostInternational>
          <BtnCostInternational
            id="10"
            href={id ? "" : "https://donate.stripe.com/bIY4iqe68eQT3tu29o"}
          ></BtnCostInternational>
          <BtnCostInternational
            id="25"
            href={id ? "" : "https://donate.stripe.com/00gg184vycIL0hi8xQ"}
          ></BtnCostInternational>
          <BtnCostInternational
            id="50"
            href={id ? "" : "https://donate.stripe.com/8wM4iq9PSgZ18NO29r"}
          ></BtnCostInternational>
          <BtnCostInternational
            id="100"
            href={id ? "" : "https://donate.stripe.com/00g6qyfacgZ10hi6pF"}
          ></BtnCostInternational>
          <BtnCostInternational
            id="500"
            href={id ? "" : "https://donate.stripe.com/8wM7uC2nqdMP0hi9BS"}
          ></BtnCostInternational>
        </div>


        <div className="type-of-cards-inter">
          <img className="header-master_logo" src={logoMaster} alt="" />
          <img className="header-visa_logo" src={logoVisa} alt="" />
          <img className="header-google_logo" src={logoGoogle} alt="" />
          <img className="header-apple_logo" src={logoApple} alt="" />
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "./documents.scss"
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Documents = () => {
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const { docId } = useParams();
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  const documentsPdf = t("about-as.documents", { returnObjects: true });

  const findedPdf = documentsPdf.find((item) => docId === item.id);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offSet) {
    setPageNumber((prevPageNumber) => prevPageNumber + offSet);
  }

  function changePageBack() {
    changePage(-1);
  }

  function changePageNext() {
    changePage(+1);
  }

  return (
    <div className="documents_pdf">
      <div className="document-bg"></div>
      <div className="documents_box-button">
        <button className="button-donation" onClick={goBack}>
          Go back
        </button>
      </div>
      <div className="documents_wrapper">
        <Document file={findedPdf.pdf} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber}></Page>
        </Document>
      </div>
      <p className="documents-title">
        Page {pageNumber} of {numPages}
      </p>
      <div className="documents_box-button">
        {pageNumber > 1 && (
          <button className="button-donation" onClick={changePageBack}>
            Previous Page
          </button>
        )}
        {pageNumber < numPages && (
          <button className="button-donation" onClick={changePageNext}>
            Next page
          </button>
        )}
      </div>
    </div>
  );
};

export default Documents;

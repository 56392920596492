import RobotaUa from "./rabota-ua.png";
import Lustra from "./lustra.png";
import CP from "./cty-prof.png";
import EasyProject from "./easy.svg";
import LikiFond from "./likifor.png";
import Auchan from "./auchan.png";
import M from "./m.png";
import Reply from "./replay.png";
import Liki24 from "./liki24.png";
import Volia from "./Volia.png";
import Data from "./DATA_Logotype.png";
import Eva from "./logo_EVA_2019.png";


const logoPartners = [
  {
    id: 1,
    name: "RobotaUa",
    url: RobotaUa,
  },
  {
    id: 2,
    name: "Lustra",
    url: Lustra,
  },
  {
    id: 3,
    name: "CP",
    url: CP,
  },
  {
    id: 4,
    name: "EasyProject",
    url: EasyProject,
  },
  {
    id: 5,
    name: "LikiFond",
    url: LikiFond,
  },
  {
    id: 6,
    name: "Auchan",
    url: Auchan,
  },
  {
    id: 7,
    name: "M",
    url: M,
  },
  {
    id: 8,
    name: "Reply",
    url: Reply,
  },
  {
    id: 9,
    name: "Liki24",
    url: Liki24,
  },
  {
    id: 10,
    name: "Volia",
    url: Volia,
  },
  {
    id: 11,
    name: "Data",
    url: Data,
  },
  {
    id: 12,
    name: "Eva",
    url: Eva,
  },
];

export default logoPartners;
